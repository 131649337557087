import React, { createContext, useContext, useState, useEffect } from 'react';

const HouseholdListFilterContext = createContext();

export const HouseholdListFilterProvider = ({ children }) => {

    const defaultFilters = {
        tenantId: process.env.REACT_APP_TENANT,
        serviceIds: [4],
        onlyHasBlueBadge: false,
        onlyShowRejected: false,
        onlyShowReferrerToCollect: false,
        onlyShowNoCollectionTime: false,
        sortBy: "name",
        sortOrder: "asc",
        scrollPosition: 0,
        filterType: "All",
    };

    const storedFilters = JSON.parse(localStorage.getItem('householdListFilters')) || defaultFilters;
    const storedScrollPosition = JSON.parse(localStorage.getItem('householdListScrollPosition')) || 0;
    const [selectedFilters, setSelectedFilters] = useState(storedFilters);
    const [scrollPosition, setScrollPosition] = useState(storedScrollPosition);

    useEffect(() => {
        localStorage.setItem('householdListFilters', JSON.stringify(selectedFilters));
    }, [selectedFilters]);

    useEffect(() => {
        localStorage.setItem('householdListScrollPosition', JSON.stringify(scrollPosition));
    }, [scrollPosition]);

    return (
        <HouseholdListFilterContext.Provider
            value={{
                selectedFilters,
                setSelectedFilters,
                scrollPosition,
                setScrollPosition,
            }}
        >
            {children}
        </HouseholdListFilterContext.Provider>
    );
};

export const useFilters = () => useContext(HouseholdListFilterContext);