import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        const mainElement = document.getElementById("main");

        if (!mainElement || pathname === "/households") return;
            mainElement.scrollTop = 0;

    }, [pathname]);

    return null;
}
